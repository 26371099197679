<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <b-card v-else>
            <template v-slot:header>
                <h5>Approve Void Pembelian</h5>
            </template>
            <b-row>
                <b-col>
                    <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                        <div class="d-flex search-input-wrapper">
                            <b-form-input class="ml-2 mr-2" v-model="filter.no_invoice" name="filterInvoice"
                                id="filterInput" type="search" placeholder="No Invoice"
                                @keyup="getVoidPembelian()"></b-form-input>

                            <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                                @click="onReset()">Reset</b-button>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-table bordered hover show-empty :busy="isBusy" :items="pembelian" :fields="fields" :perPage="perPage"
                @filtered="onFiltered" responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data jenis barang.
                </template>
                <template v-slot:cell(status)="row">
                    <b-badge variant="primary" v-if="row.item.status == 0">Belum Proses</b-badge>
                    <b-badge variant="success" v-if="row.item.status == 1">Sudah Diproses</b-badge>
                    <b-badge variant="success" v-if="row.item.status == 2">Sudah Dibayar</b-badge>
                    <b-badge variant="success" v-if="row.item.status == 3">Diterima</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 4">Dibatalkan</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 5">Barang Selisih</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 6">Permintaan Void</b-badge>

                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <b-link :to="`${$route.path}/${row.item.id}/detail`">
                            <b-button size="sm" class="mr-3 table-button view" v-b-tooltip.hover title="Detail"
                                name="pembelianViewBtn">
                                <i class="fa fa-info"></i>
                            </b-button>
                        </b-link>
                    </div>
                </template>
            </b-table>

            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <span class="ml-2">{{ totalViewText }}</span>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>

        <!-- MODAL CREATE -->
        <b-modal id="m-create" class="modal-lg" title="Formulir Pembelian" no-close-on-backdrop no-close-on-esc hide-footer>

            <b-row>
                <b-col>
                    <b-form-group label="Supplier">
                        <multiselect v-model="form.id_supplier" :options="supplier" :internal-search="true"
                            :allow-empty="false" deselect-label="" select-label="" label="nama" track-by="id"
                            placeholder="-- Pilih Supplier --" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Ongkir" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                        <b-input-group class="pt-2">
                            <b-input-group-prepend>
                                <b-input-group-text>Rp.</b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input v-model="form.ongkir" id="ongkir" placeholder="" required=""></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group>
                <p>Keterangan</p>
                <b-form-textarea v-model="form.keterangan" id="basicTextarea" :rows="9"
                    placeholder="Content.."></b-form-textarea>
            </b-form-group>

            <div class="mt-4 text-right">
                <b-button variant="pgiBtn" @click="prepare('m-confirm-create')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <ModalConfirm id="m-confirm-create" title="Confirm Tambah Pembelian" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menambah Pembelian ini ?" @submit="submitInput" />
    </div>
</template>

<script>
import { thisExpression } from '@babel/types'

export default {
    name: 'approval-void-pembelian',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            isSubmitting: false,
            isLoading: false,
            activeId: null,
            input: {
                show: 10
            },

            form: {
                tanggal: "",
                id_supplier: null,
                pic: "0999",
                ongkir: "",
                file: null,
                keterangan: ""
            },

            filter: {
                no_invoice: null,
                user_input: null,
            },
            fields: [
                // { key: "no", label: "No", sortable: true },
                { key: "no_invoice", label: "No Invoice" },
                { key: "tanggal", label: "Tanggal" },
                { key: "supplier", label: "Supplier" },
                { key: "totalUnit", label: "Total Unit" },
                { key: "totalPembelian", label: "Total Pembelian" },
                { key: "status", label: "Status" },
                { key: "action", label: "Aksi" },
            ],

            options: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 30, text: '30' },
                { value: 40, text: '40' },
                { value: 50, text: '50' },
            ],
            pembelianAll: [],
            pembelian: [],
            supplier: [],
            supplierValue: [],

            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            query: "",
        }
    },
    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        init() {
            this.initStatus = 0
            this.page = 1
            this.query = "?page=" + this.page

            let getSuplier = this.$axios.get("api/supplier/all").then(response => {
                let datas = response.data
                for (var data of datas.data) {
                    this.supplier.push({
                        id: data.id,
                        nama: data.nama
                    })
                }
            })

            let getVoidPembelian = this.$axios.get("api/transaksi/pembelian?flag=6").then(response => {
                let datas = response.data.data

                for (var data of datas.data) {

                    if (data.supplier == null) {
                        data.supplier = { nama: '-' }
                    }

                    let nama = data.supplier.nama

                    let total_pembelian = "Rp. " + data.total_pembelian.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

                    this.pembelian.push({
                        id: data.id,
                        no_invoice: data.no_invoice,
                        tanggal: data.tanggal,
                        supplier: nama,
                        totalUnit: data.total_unit,
                        totalPembelian: total_pembelian,
                        status: data.flag
                    })
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to

                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })

            Promise.all([getSuplier, getVoidPembelian]).then(() => {
                this.initStatus = 1
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },

        getVoidPembelian() {
            this.toggleBusy()
            console.log("ke get")
            console.log(this.query)

            this.$axios.get("api/transaksi/pembelian?flag=6" + this.query).then(response => {
                this.pembelian = []
                let datas = response.data.data

                for (var data of datas.data) {

                    if (data.supplier == null) {
                        data.supplier = { nama: '-' }
                    }

                    let nama = data.supplier.nama

                    let total_pembelian = "Rp. " + data.total_pembelian.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

                    this.pembelian.push({
                        id: data.id,
                        no_invoice: data.no_invoice,
                        tanggal: data.tanggal,
                        supplier: nama,
                        totalUnit: data.total_unit,
                        totalPembelian: total_pembelian,
                        status: data.flag
                    })
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
                this.isLoading = false

                this.toggleBusy()
                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    this.toggleBusy()
                    this.isLoading = false
                })
        },

        prepare(modalToShow) {


            if (this.form.id_supplier == null) {
                return this.$helper.toastErr(this, "Mohon mengisi Supplier!")
            }

            if (this.form.ongkir == "") {
                return this.$helper.toastErr(this, "Mohon mengisi Ongkir!")
            }

            this.$bvModal.show(modalToShow)

        },


        submitInput() {

            var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '-');


            let ongkir = this.$helper.removeThousandSeparator(this.form.ongkir)
            let form = {
                tanggal: currentDateWithFormat,
                id_supplier: this.form.id_supplier.id,
                pic: "0999",
                ongkir: ongkir,
                file: null,
                keterangan: (this.form.keterangan != "") ? this.form.keterangan : "-"
            }
            this.isSubmitting = true

            this.$axios.post("api/transaksi/pembelian", form).then(res => {

                this.$helper.toastSucc(this, "Pembelian Berhasil Ditambahkan")
                this.$bvModal.hide("m-confirm-create")
                this.$bvModal.hide("m-create")

                this.idJenisInput = null
                this.formInput = {
                    tipe: "",
                    satuan: "",
                    harga: null,
                    tipe_kode: null,
                    kode_barang: "",
                    id_merk: null
                }
                this.getVoidPembelian()

            })
                .catch(error => this.$helper.parseError(this, error))
                .finally(() => this.isSubmitting = false)

        },


        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

        onPageChange() {

            if (this.isLoading == false) {

                this.page = this.currentPage
                this.query = "&page=" + this.page

                this.getVoidPembelian()
            }

        },


        onFilter(letters) {

            if (this.filter == null) {
                this.$helper.toastErr(this, "Silakan isi nama jenis barang terlebih dahulu!")
            } else {
                let filteredArray = []

                for (var x of this.pembelianAll) {
                    if (x.name.toLowerCase().includes(letters.toLowerCase())) {
                        filteredArray.push(x)
                    }
                }

                this.pembelian = filteredArray
                this.onFiltered(this.pembelian)
            }
        },

        onFiltered(filteredItems) {
            this.rows = filteredItems.length
            this.currentPage = 1
            this.perPage = this.input.show
            let prevLimit = null
            let limit = null

            prevLimit = (this.currentPage - 1) * this.rows + 1
            limit = (this.currentPage - 1) * this.rows + this.rows
            if (limit > this.input.show) limit = this.input.show
            this.totalViewText = "Menampilkan " + prevLimit + " - " + limit + " dari " + this.rows + " entri"
        },

        onReset() {
            this.filter = {
                no_invoice: null,
                user_input: null,
            },

                this.page = 1
            this.query = "?page=" + this.page
            this.currentPage = 1
            this.loading = true
            this.getVoidPembelian()
        },



        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },


        inputJenisBarang() {
            this.$router.push({ path: `${this.$route.path}/input` });
        },
    },
    watch: {

        //----------------- Pantau Jenis Barang -----------------

        "form.ongkir": function (newValue) {
            let cleanValue = this.$helper.changeToValue(newValue);
            const result = cleanValue.replace(/(?!^-)[^0-9]/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            this.$nextTick(() => this.form.ongkir = result);
        },
    },

    created() {
        this.init()
    }
}
</script>
